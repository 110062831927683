import React from 'react';
import i18n from "../i18n";
import routes from "../routes";
import {debounce, pwAssetUrl} from "../utils/etc";
import AppContext from "../contexts/AppContext";
import {logEvent, userEvents} from "../utils/log";
import {webviewPreloadRewardedAd} from "../utils/webview";
import processingManager from "../photolab/ProcessingManager";
import Processing from "../photolab/Processing";
import {generatePath} from "react-router";

export default class IndexPage extends React.Component {

  state = {};

  componentDidMount() {
    const restoredProcessing = processingManager.restore();
    if (restoredProcessing != null) {
      const startedAt = restoredProcessing.getExtra(Processing.EXTRA_STARTED_AT);
      if (startedAt + 3_600_000 >= Date.now()) {
        this.props.history.replace(generatePath(routes.PROCESSING));
        return;
      }
    }

    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("IndexPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_INDEX);
        });
      }
    }, true);

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_INDEX);
    }

    this.context.hideLoader();
  }

  handleChoosePhotoButtonClick = () => {
    if (window.clientConfig.features.isRewardedAdModel) {
      webviewPreloadRewardedAd(window.clientConfig.features.rewardedAdUnitId);
    }

    this.props.history.push(routes.PHOTOCHOOSER);
  };

  renderDefault = () => {
    const classNames = ["main-page"];

    return <main className={classNames.join(" ")}>
      <div className="container">
        <div className="content-container">

          <div className="content-video">
            <video
              className="main-video"
              playsInline
              autoPlay
              loop
              muted>
              <source type="video/mp4" src={pwAssetUrl(`mylooks/avatar_ai_motion_banner_1_v3.mp4`)} />
            </video>
          </div>

          <h1 dangerouslySetInnerHTML={{__html: i18n.t("index_title")}} />
          <p dangerouslySetInnerHTML={{__html: i18n.t("index_subtitle")}} />

          <button
            className="btn-upload-foto"
            onClick={this.handleChoosePhotoButtonClick}
            children={i18n.t("upload_choose_photo_button")} />
        </div>
      </div>
    </main>;
  }

  render() {
    return this.renderDefault();
  }
}

IndexPage.contextType = AppContext;
