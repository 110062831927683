import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import routes from "../routes";
import * as webviewUtils from "../utils/webview";
import AppContext from "../contexts/AppContext";
import Creative from "../photolab/Creative";
import processingManager from "../photolab/ProcessingManager";
import {generatePath} from "react-router";
import i18n from "../i18n";
import {logEvent, userEvents} from "../utils/log";
import {debounce} from "../utils/etc";
import CreativeView from "./CreativeView";
import {
  webviewAnalyticsEvent, webviewAppIds,
  webviewConsumable,
  webviewInApp, webviewOpenBrowser
} from "../utils/webview";
import clientStorage from "../utils/client-storage";
import SubscribeBannerView from "./SubscribeBannerView";
import PurchaseBannerView from "./PurchaseBannerView";
import {getInAppSku, getSubscriptionSku} from "../utils/inapp";
import inappsHelper from "../helpers/inapps.helper";
import {saveAs} from "file-saver";
import {creativeIsPaywall} from "../utils/creative";
import RewardedAdBannerView from "./RewardedAdBannerView";
import {isWebviewApp} from "../utils/config.utils";

export default class ResultPage extends React.Component {

  state = {
    isReady: false,
    updatedAt: 0,
    activeIndex: 0,
    sku: "nosku",
    rewardedAdIsLoaded: false,
  };

  swiperRef = React.createRef();

  constructor(props) {
    super(props);

    if (window.clientConfig.features.isSubscribeModel) {
      this.state.sku = getSubscriptionSku();
    } else if (window.clientConfig.features.isPurchaseModel) {
      this.state.sku = getInAppSku();
    }
  }

  componentDidMount() {
    window.webviewEventsListeners.tabSelected.subscribe((millis) => {
      if (millis > 0) {
        debounce("ResultPage.handleWebviewTabSelected", 300, () => {
          logEvent(userEvents.PAGE_RESULT);
          webviewAnalyticsEvent("result_screen_shown", [
            "",
            "result",
            clientStorage.getSelectedPhotosAmount()
          ]);
        });
      }
    }, true);

    window.webviewEventsListeners.rewardedAdClosed.setListener((flag) => {
      if (flag && processingManager.processing) {
        processingManager.processing.creatives.forEach((creative) => {
          if (creative.getExtra(Creative.EXTRA_IS_NON_FREE)) {
            creative.setExtra(Creative.EXTRA_IS_PAID, true);
          }
        });
        this.handleRefresh();

        webviewAnalyticsEvent("rewarded_finish", [
          "",
          "result",
          clientStorage.getSelectedPhotosAmount(),
        ]);
      }
    });

    if (window.clientConfig.isWeb) {
      logEvent(userEvents.PAGE_RESULT);
    }

    if (window.clientConfig.isWebview) {
      if (window.clientConfig.features.isRewardedAdModel) {
        webviewUtils.webviewIsRewardedAdPreloaded(window.clientConfig.features.rewardedAdUnitId).then((isLoaded) => {
          this.setState({rewardedAdIsLoaded: isLoaded});
          webviewAnalyticsEvent("rewarded_received", [
            "",
            "result",
            clientStorage.getSelectedPhotosAmount(),
          ]);
        });
      }

      webviewUtils.webviewCheckInstalledApps((apps) => {
        this.context.setInstalledApps(apps);
      });
    }

    processingManager.addOnProcessingChangeHandler(this.handleProcessingChanged);

    if (processingManager.processing === null) {
      const restoredProcessing = processingManager.restore();

      if (restoredProcessing) {
        try {
          processingManager.start(restoredProcessing);
          this.setState({isReady: true});
        } catch (err) {
          console.error(err);
          this.props.history.replace(generatePath(routes.INDEX));
        }
      } else {
        this.props.history.replace(generatePath(routes.INDEX));
      }
    } else {
      this.setState({isReady: true});
      this.handleProcessingChanged();
    }
  }

  componentWillUnmount() {
    processingManager.removeOnProcessingChangeHandler(this.handleProcessingChanged);
  }

  getPurchase = () => {
    const sku = getInAppSku();

    let purchase = this.context.purchases
      .filter((p) => p.product_id === sku)
      .sort((a, b) => b.available - a.available)
      .first();

    // todo удалить проверку через неделю (27 марта)
    if (!purchase) {
      const uniteSku = isWebviewApp(webviewAppIds.toonme) ? "tm_unite_1" : "pl_unite_1";

      purchase = this.context.purchases
        .filter((p) => p.product_id === uniteSku)
        .sort((a, b) => b.available - a.available)
        .first();
    }

    return purchase;
  };

  handleProcessingChanged = () => {
    this.context.hideLoader();
    clientStorage.setLatestSelectedImages([]);

    const startedCreatives = processingManager.processing.getStartedCreatives();
    const nonFreeCreatives = startedCreatives.filter((c) => {
      return c.getExtra(Creative.EXTRA_IS_NON_FREE, false) && !c.hasExtra(Creative.EXTRA_IS_PAID);
    });

    if (nonFreeCreatives.isNotEmpty()) {
      let paidAmount = 0;

      if (window.clientConfig.features.isSubscribeModel && window.clientConfig.isPro) {
        paidAmount = nonFreeCreatives.length;
      } else if (window.clientConfig.features.isPurchaseModel) {
        const purchase = this.getPurchase();
        if (purchase) {
          paidAmount = purchase.available >= nonFreeCreatives.length
            ? nonFreeCreatives.length
            : purchase.available;

          if (paidAmount > 0) {
            inappsHelper.consume(purchase.id, paidAmount).then((res) => {
              this.context.updatePurchase(res.data);
            });
          }
        }
      }

      if (paidAmount > 0) {
        nonFreeCreatives.slice(0, paidAmount).forEach((nonFreeCreative) => {
          nonFreeCreative.setExtra(Creative.EXTRA_IS_PAID, true);
        });

        processingManager.update();
      }
    }

    this.setState({
      updatedAt: Date.now(),
    });
  };

  handleActiveIndexChange = (swiper) => {
    this.setState({
      activeIndex: swiper.activeIndex,
    });

    if (this.swiperRef.current.isEnd) {
      webviewAnalyticsEvent("button_tapped", ["another_go", "result"]);
      this.handleRefresh();
    }
  }

  handleRefresh = () => {
    const nextCreatives = processingManager.processing.getNextCreatives(window.clientConfig.features.creativesRefreshAmount);
    const nonFreeNextCreatives = nextCreatives.filter((c) => c.getExtra(Creative.EXTRA_IS_NON_FREE, false));
    const startedCreatives = processingManager.processing.getStartedCreatives();
    const nonFreeStartedCreatives = startedCreatives.filter((c) => c.getExtra(Creative.EXTRA_IS_NON_FREE, false));
    const nonPaidStartedCreatives = nonFreeStartedCreatives.filter((c) => !c.hasExtra(Creative.EXTRA_IS_PAID));

    if (nextCreatives.isEmpty()) {
      return;
    }

    if (nonFreeNextCreatives.isEmpty()) {
      this.startNextCreatives(nextCreatives, true);
      return;
    }

    if (window.clientConfig.features.isSubscribeModel) {
      if (window.clientConfig.isPro) {
        this.startNextCreatives(nextCreatives, true);
      } else {
        if (nonPaidStartedCreatives.isEmpty()) {
          this.startNextCreatives(nextCreatives, false);
        }
      }
    } else if (window.clientConfig.features.isPurchaseModel) {
      const purchase = this.getPurchase();
      if (purchase && purchase.available > 0) {
        const paidAmount = purchase.available >= nonFreeNextCreatives.length
          ? nonFreeNextCreatives.length
          : purchase.available;

        if (paidAmount > 0) {
          inappsHelper.consume(purchase.id, paidAmount).then((res) => {
            this.context.updatePurchase(res.data);
          });
        }

        this.startNextCreatives(nextCreatives.slice(0, paidAmount), true);
      } else {
        if (nonPaidStartedCreatives.isEmpty()) {
          this.startNextCreatives(nextCreatives, false);
        }
      }
    } else {
      this.startNextCreatives(nextCreatives, true);
    }
  };

  startNextCreatives = (nextCreatives, isPaid) => {
    if (nextCreatives.isEmpty()) {
      return;
    }

    const startedCreatives = processingManager.processing.getStartedCreatives();

    nextCreatives.forEach((nextCreative, index) => {
      nextCreative.removeExtra(Creative.EXTRA_KEEP_PENDING);

      if (isPaid) {
        nextCreative.setExtra(Creative.EXTRA_IS_PAID, true);
      }

      webviewAnalyticsEvent("generation_start", [
        "",
        "result",
        clientStorage.getSelectedPhotosAmount(),
        startedCreatives.length + index + 1,
        "",
        nextCreative.templateId,
      ]);
    });

    processingManager.processing.setExtra("batches_amount", processingManager.processing.getExtra("batches_amount", 0) + 1);
    processingManager.update();

    this.handleProcessingChanged();
  };

  handleNextButtonClick = (source) => {
    if (this.swiperRef.current.isEnd) {
      if (window.clientConfig.features.isSubscribeModel) {
        this.handleSubscribeButtonClick();
      } else if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
        webviewAnalyticsEvent("rewarded_start", [
          "",
          "result",
          clientStorage.getSelectedPhotosAmount(),
        ]);
        webviewUtils.webviewShowRewardedAd(
          window.clientConfig.features.rewardedAdUnitId,
          "onRewardedAdShown",
          "onRewardedAdClosed"
        );
      } else if (window.clientConfig.features.isPurchaseModel) {
        this.handlePurchaseButtonClick();
      }

      return;
    }

    this.swiperRef.current.slideNext();
  };

  handlePrevButtonClick = () => {
    this.swiperRef.current.slidePrev();
  }

  handleSubscribeButtonClick = () => {
    webviewAnalyticsEvent("subscribe_button_tapped", [
      "makeover_wt8_v1",
      "result",
      this.state.sku,
    ]);

    webviewInApp(this.state.sku);
  };

  handlePurchaseButtonClick = () => {
    webviewAnalyticsEvent("inapp_button_tapped", [
      "makeover_i1_v1",
      "result",
      this.state.sku,
    ]);

    webviewConsumable(this.state.sku);
  };

  handleBackButtonClick = () => {
    processingManager.clear();
    this.props.history.replace(routes.INDEX);
  };

  logCreativeDownload = (creative, type) => {
    const position = processingManager.processing.findCreativePosition(creative);

    webviewAnalyticsEvent("save_and_share", [
      "",
      "result",
      clientStorage.getSelectedPhotosAmount(),
      position + 1,
      type,
      creative.templateId,
    ]);
  };

  startDownloadImageUrl = (imageUrl, creative) => {
    if (window.clientConfig.isWebviewAndroid) {
      this.logCreativeDownload(creative, "download");
    }

    if (window.clientConfig.isWebview) {
      this.context.setSharePromotionVisible(true, 0, imageUrl);
      webviewUtils.webviewShare({
        providers: window.clientConfig.isWebviewIOS ? encodeURIComponent("[0]") : "[0]",
        imageUrl: encodeURIComponent(imageUrl),
        // autosave: 1,
      }, () => {
        if (window.clientConfig.isWebviewIOS) {
          this.logCreativeDownload(creative, "download");
        }
      });
    } else {
      const fileName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
      saveAs(imageUrl, fileName);
    }
  };

  startShareImageUrl = (imageUrl, creative) => {
    if (window.clientConfig.isWebviewAndroid) {
      this.logCreativeDownload(creative, "share");
    }

    const providers = [0];
    const installedApps = this.context.installedApps || {};

    installedApps.snapchat && providers.push(webviewUtils.shareProvidersIds.snapchat);
    installedApps.instagram && providers.push(webviewUtils.shareProvidersIds.instagram);
    installedApps.facebook && providers.push(webviewUtils.shareProvidersIds.facebook);
    installedApps.whatsapp && providers.push(webviewUtils.shareProvidersIds.whatsapp);
    installedApps.facebookMessenger && providers.push(webviewUtils.shareProvidersIds.facebookMessenger);
    installedApps.telegram && providers.push(webviewUtils.shareProvidersIds.telegram);

    let providersStr = "[" + providers.join(",") + "]";
    if (window.clientConfig.isWebviewIOS) {
      providersStr = encodeURIComponent(providersStr);
    }

    webviewUtils.webviewShare({
      providers: providersStr,
      imageUrl: encodeURIComponent(imageUrl),
      autosave: 0,
    }, () => {
      if (window.clientConfig.isWebviewIOS) {
        this.logCreativeDownload(creative, "share");
      }
    });
  };

  handleSubscriptionTermsClick = (e) => {
    if (e.target.tagName.toLowerCase() === "span") {
      webviewOpenBrowser("https://pho.to/terms.html", window.clientConfig.isWebviewIOS ? "safari" : undefined);
    }
  };

  renderBanner = (creative, activeCreative) => {
    if (creativeIsPaywall(creative)) {
      if (window.clientConfig.features.isSubscribeModel) {
        return <SubscribeBannerView isOnScreen={creative.id === activeCreative.id} />;
      }

      if (window.clientConfig.features.isRewardedAdModel && this.state.rewardedAdIsLoaded) {
        return <RewardedAdBannerView isOnScreen={creative.id === activeCreative.id} />;
      }

      if (window.clientConfig.features.isPurchaseModel) {
        return <PurchaseBannerView isOnScreen={creative.id === activeCreative.id} />;
      }
    }

    return null;
  };

  render() {
    if (!this.context.loader.isHidden || !this.state.isReady) {
      return <React.Fragment />;
    }

    // const creatives = processingManager.processing.creatives
    //   .filter((creative) => creative.hasExtra(Creative.EXTRA_STARTED_AT))
    //   .sort((a, b) => a.getExtra(Creative.EXTRA_STARTED_AT) - b.getExtra(Creative.EXTRA_STARTED_AT));

    const creatives = processingManager.processing.creatives
      .filter((creative) => !creative.getExtra(Creative.EXTRA_KEEP_PENDING, false));

    const creativesTotal = processingManager.processing.creatives.length;
    const activeCreative = creatives[this.state.activeIndex];
    const creativesItems = creatives.slice();
    const classNames = ["result-page"];

    return <main className={classNames.join(" ")}>
      <div className="container">
        <div className="header">
          <button className="btn-back" onClick={this.handleBackButtonClick}>
            <svg viewBox="0 0 25 16" fill="none">
              <path d="M.293 7.293a1 1 0 0 0 0 1.414l6.364 6.364a1 1 0 0 0 1.414-1.414L2.414 8l5.657-5.657A1 1 0 0 0 6.657.93L.293 7.293zM1 9h24V7H1v2z" fill="#fff"/>
            </svg>
          </button>
          <p>{i18n.t("result_title")}</p>
        </div>
        
        <div
          className="result-notice"
          hidden={!this.context.sharePromotionIsShown}
          onClick={() => this.startShareImageUrl(this.context.sharePromotionImageUrl)}>
          <p>
            <span dangerouslySetInnerHTML={{__html: i18n.t(this.context.sharePromotionTextKey)}} />
            <SvgArrowIcon />
          </p>
        </div>

        <div className="results-grid results-grid-list">
          <Swiper
            spaceBetween={10}
            slidesPerView={window.clientConfig.isWebDesktop ? 1 : 1.12}
            centeredSlides={true}
            onSlideChange={() => this.context.setSharePromotionVisible(false, 0, null)}
            onActiveIndexChange={this.handleActiveIndexChange}
            onSwiper={(swiper) => this.swiperRef.current = swiper}>
            {creativesItems.map((creative, position) => <SwiperSlide key={creative.id}>
              <CreativeView
                creative={creative}
                position={position}
                isSelected={creative.id === activeCreative.id}
                isOverlayed={creativeIsPaywall(creative)}
                downloadHandler={(imageUrl) => this.startDownloadImageUrl(imageUrl, creative)}
                shareHandler={(imageUrl) => this.startShareImageUrl(imageUrl, creative)}
              />
              {this.renderBanner(creative, activeCreative)}
            </SwiperSlide>)}
          </Swiper>
        </div>

        <div className="slider-nav">
          <button
            className="slider-nav-prev"
            hidden={this.state.activeIndex === 0}
            onClick={this.handlePrevButtonClick}>
            <svg viewBox="0 0 25 8">
              <path d="M.646 3.646a.5.5 0 0 0 0 .708l3.182 3.182a.5.5 0 1 0 .708-.708L1.707 4l2.829-2.828a.5.5 0 1 0-.708-.708L.646 3.646zM1 4.5h24v-1H1v1z"/>
            </svg>
          </button>

          <p className='slider-counter'>{this.state.activeIndex + 1}/{creativesTotal}</p>

          <button
            className="slider-nav-next"
            hidden={this.state.activeIndex === (creativesTotal - 1)}
            onClick={() => this.handleNextButtonClick("slider_nav")}>
            <svg viewBox="0 0 25 8">
              <path d="M24.354 3.646a.5.5 0 0 1 0 .708l-3.182 3.182a.5.5 0 1 1-.707-.708L23.293 4l-2.828-2.828a.5.5 0 1 1 .707-.708l3.182 3.182zM24 4.5H0v-1h24v1z" />
            </svg>
          </button>
        </div>

        <div className="btns-container">
          <button
            hidden={this.state.activeIndex === (creativesTotal - 1)}
            className={"btn-refresh"}
            onClick={() => this.handleNextButtonClick("primary")}>
            {i18n.t("next")}
            <svg viewBox="0 0 25 8">
              <path d="M24.354 4.354a.5.5 0 0 0 0-.708L21.172.464a.5.5 0 1 0-.707.708L23.293 4l-2.828 2.828a.5.5 0 1 0 .707.708l3.182-3.182zM0 4.5h24v-1H0v1z"/>
            </svg>
          </button>
        </div>

        <div className={`subscribe-footer-container ${window.clientConfig.features.isSubscribeModel && creativeIsPaywall(activeCreative) ? "subscribe-footer-container-show" : "subscribe-footer-container-hide"}`}>
          <p
            className="subscribe-text"
            onClick={this.handleSubscriptionTermsClick}
            dangerouslySetInnerHTML={{__html: i18n.t("subscribe_subscription_terms")}}
          />
        </div>

      </div>
    </main>;
  }
}

function SvgArrowIcon() {
  return <svg viewBox="0 0 24 24" fill="none">
    <path d="M5 12h14M12 5l7 7-7 7" stroke="#F8D34F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>;
}

ResultPage.contextType = AppContext;
