/* eslint-disable no-unused-vars */

import Creative from "../Creative";
import groups from "./groups";
import {
  combo,
  stepWithFallback,
  setCreativesGroup,
  setCreativesHiddenAfterAt,
  setCreativesVisibleAfterAt,
  whenSplit,
  whenSplitInRange,
  getCreativesInRandomOrder,
  skipOnMultifaceError,
  getCreativesInRandomOrderWithWeights
} from "./helpers";
import CreativeConfig from "../CreativeConfig";
import {handlersNames} from "../handlers";
import {sort} from "css-loader/dist/utils";

function getGroups() {
  return [
    groups.g1,
  ];
}

const positionsTemplatesPairs = [
  {canvasId: 8206, maskId: 8207},
];

const promptTemplates = {
  male: [
    {id: 8541, weight: 3.47868506},
    {id: 8534, weight: 3.173503161},
    {id: 8373, weight: 3.040745278},
    {id: 8296, weight: 2.969940294},
    {id: 8518, weight: 2.885875856},
    {id: 8342, weight: 2.77512837},
    {id: 8522, weight: 2.665613949},
    {id: 8289, weight: 2.531370152},
    {id: 8535, weight: 2.506044581},
    {id: 8547, weight: 2.281696624},
    {id: 8546, weight: 2.21686459},
    {id: 8539, weight: 2.070108381},
    {id: 8519, weight: 2.021561116},
    {id: 8297, weight: 2.010409618},
    {id: 8340, weight: 1.993463544},
    {id: 8314, weight: 1.965828678},
    {id: 8412, weight: 1.918298572},
    {id: 8448, weight: 1.876513432},
    {id: 8545, weight: 1.836082529},
    {id: 8536, weight: 1.822343625},
    {id: 8303, weight: 1.813555809},
    {id: 8408, weight: 1.803539253},
    {id: 8384, weight: 1.772720484},
    {id: 8335, weight: 1.698218733},
    {id: 8537, weight: 1.688639873},
    {id: 8446, weight: 1.676740778},
    {id: 8302, weight: 1.673609926},
    {id: 8455, weight: 1.670040661},
    {id: 8298, weight: 1.660703889},
    {id: 8542, weight: 1.622439651},
    {id: 8382, weight: 1.610016623},
    {id: 8507, weight: 1.560526872},
    {id: 8540, weight: 1.530533668},
    {id: 8429, weight: 1.484102934},
    {id: 8364, weight: 1.402489306},
    {id: 8538, weight: 1.385429853},
    {id: 8544, weight: 1.376443715},
    {id: 8301, weight: 1.358075825},
    {id: 8385, weight: 1.350118401},
    {id: 8361, weight: 1.333164375},
    {id: 8407, weight: 1.323459942},
    {id: 8440, weight: 1.279502661},
    {id: 8449, weight: 1.24920073},
    {id: 8427, weight: 1.231800176},
    {id: 8447, weight: 1.213782105},
    {id: 8333, weight: 1.209147064},
    {id: 8454, weight: 1.181619801},
    {id: 8436, weight: 1.178121345},
    {id: 8426, weight: 1.168350235},
    {id: 8418, weight: 1.153455438},
    {id: 8378, weight: 1.087834188},
    {id: 8332, weight: 1.062511422},
    {id: 8516, weight: 0.984978071},
    {id: 8510, weight: 0.9787108574},
    {id: 8439, weight: 0.9656938618},
    {id: 8400, weight: 0.9525431383},
    {id: 8414, weight: 0.8925367633},
    {id: 8453, weight: 0.7247641757},
    {id: 8543, weight: 0.6507999871},
  ].map((item) => {
    item.weight = Math.floor(item.weight ** 3);
    return item;
  }),
  female: [
    {id: 8534, weight: 3.609008942},
    {id: 8296, weight: 3.385639174},
    {id: 8373, weight: 2.825131501},
    {id: 8546, weight: 2.817033548},
    {id: 8342, weight: 2.751391329},
    {id: 8412, weight: 2.71416512},
    {id: 8522, weight: 2.6870266},
    {id: 8340, weight: 2.556767345},
    {id: 8297, weight: 2.546496112},
    {id: 8314, weight: 2.363955345},
    {id: 8298, weight: 2.344227262},
    {id: 8303, weight: 2.276376347},
    {id: 8335, weight: 2.219649815},
    {id: 8536, weight: 2.097964849},
    {id: 8518, weight: 2.07171749},
    {id: 8542, weight: 2.031501722},
    {id: 8302, weight: 1.930554015},
    {id: 8384, weight: 1.881089087},
    {id: 8537, weight: 1.852858022},
    {id: 8539, weight: 1.81502274},
    {id: 8535, weight: 1.805984986},
    {id: 8545, weight: 1.780347187},
    {id: 8382, weight: 1.764149478},
    {id: 8364, weight: 1.729751605},
    {id: 8361, weight: 1.719449079},
    {id: 8519, weight: 1.672287388},
    {id: 8333, weight: 1.637859057},
    {id: 8385, weight: 1.63537093},
    {id: 8544, weight: 1.633053723},
    {id: 8455, weight: 1.626006594},
    {id: 8541, weight: 1.624089026},
    {id: 8448, weight: 1.602068267},
    {id: 8332, weight: 1.592914744},
    {id: 8547, weight: 1.584654725},
    {id: 8418, weight: 1.544500904},
    {id: 8540, weight: 1.48208974},
    {id: 8446, weight: 1.4553313},
    {id: 8507, weight: 1.413661115},
    {id: 8400, weight: 1.395392116},
    {id: 8436, weight: 1.384816882},
    {id: 8289, weight: 1.375400604},
    {id: 8449, weight: 1.368620753},
    {id: 8407, weight: 1.335443561},
    {id: 8408, weight: 1.332527177},
    {id: 8538, weight: 1.29685632},
    {id: 8510, weight: 1.227783693},
    {id: 8447, weight: 1.176177364},
    {id: 8429, weight: 1.034261032},
    {id: 8454, weight: 1.019709682},
    {id: 8427, weight: 0.9930885461},
    {id: 8516, weight: 0.9212416843},
    {id: 8439, weight: 0.8830770859},
    {id: 8426, weight: 0.8729374798},
    {id: 8440, weight: 0.8429944074},
    {id: 8301, weight: 0.8361233126},
    {id: 8378, weight: 0.7232952128},
    {id: 8453, weight: 0.6955498535},
    {id: 8414, weight: 0.6202354291},
    {id: 8543, weight: 0.583321594},
  ].map((item) => {
    item.weight = Math.floor(item.weight ** 3);
    return item;
  }),
};

function getRandomIndexWithWeight(array) {
  if (array.isEmpty()) {
    return -1;
  }

  const sum = array.reduce((t, item) => t + item.weight, 0);
  let r = Math.floor(Math.random() * (sum + 1));

  return array.findIndex((item) => {
    r -= item.weight;
    return r <= 0;
  });
}

export default {
  getGroups: function() {
    return getGroups();
  },
  getCreatives: function(gender, inputFiles) {
    const sortedItems = [];
    const templates = promptTemplates[gender].slice();

    while (templates.isNotEmpty()) {
      const index = getRandomIndexWithWeight(templates);
      const promptItem = templates.splice(index, 1).first();

      sortedItems.push({
        promptId: promptItem.id,
        canvasId: positionsTemplatesPairs[0].canvasId,
        maskId: positionsTemplatesPairs[0].maskId,
      });
    }

    let fileIndex = 0;

    return sortedItems.map((item) => {
      const combo = new CreativeConfig(
        groups.g1,
        item.promptId,
        handlersNames.COMBO
      );

      combo.setExtra(Creative.EXTRA_COMBO_STEPS, [
        {id: 7092, images: [{src: "@" + fileIndex}]},
        {
          id: item.promptId,
          templateParams: {
            gender: inputFiles[fileIndex].gender === "male" ? "man" : "woman",
            canvas_template_name: item.canvasId,
            mask_template_name: item.maskId,
          },
          setAsFile: "raw",
        },
      ]);

      combo.setExtra("gender", inputFiles[fileIndex].gender);

      fileIndex++;
      if (fileIndex === inputFiles.length) {
        fileIndex = 0;
      }

      return combo;
    });
  },
};
